import Vue from 'vue';
import i18n from '@/i18n';
import { Component } from 'vue-property-decorator';

export default function translate(string: string): string {
    return i18n.t(string) as string;
}

@Component
export class TranslateMixin extends Vue {
    translate (translateString: string): string {
        return translate(translateString);
    }
}
