import Vue from 'vue';
import VueI18n from 'vue-i18n';

import frontendNl from '@/translations/nl';
import frontendEn from '@/translations/en';
import * as nl from '../../translations/messages.nl.yaml';
import * as en from '../../translations/messages.en.yaml';

import { merge } from 'lodash';

const translations = {
    nl: merge(frontendNl, nl),
    en: merge(frontendEn, en),
};

global.translations = translations;

Vue.use(VueI18n);

const cachedLocale = localStorage.getItem('user_locale');
const i18n = new VueI18n({
    locale: cachedLocale || 'nl',
    messages: translations,
    silentTranslationWarn: true,
    fallbackLocale: 'nl'
});

export default i18n;
